import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import Slider from 'react-slick';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import {
  border, breakpoints, color, flex, font, resetList, sizing, title,
} from '../../utils/style';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import slots from '../../img/slots.svg';
import { categoryLinksInterpolate, getFile, validateLink } from '../../utils/system';
import dictionary from '../../utils/dictionary';
import SlickSlideMenuContentMobile from '../../components/shared/SlickSlideMenuContentMobile';
import SBOSBA from '../../components/shared/SBOSBA';
import WhySbobet from '../../components/shared/WhySbobet';

const Wrapper = styled('div')`
  p {
    margin-bottom: 1rem;
  }

  .page_description {
    margin: 60px 0 30px;

    & h1 {
      color: ${color.lightningYellow};
      font-size: ${sizing.bigHeading};
      font-weight: ${font.headingWeight};
      margin-bottom: 15px;
      margin-top: 0;
    }
    & .description_content {
      color: ${color.white};
      font-size: ${sizing.default};
    }
  }

  .sbosba-block {
    margin-top: 35px;
  }
`;

const FeaturedWrapper = styled('div')`
  user-select: none;
  position: relative;
  margin: 15px 0 0 0;
  width: 100%;
  ${title};

  .breadcrumbs {
    position: absolute;
    z-index: 1;
    top: 20px;
    left: 30px;
  }

  .gatsby-image-wrapper {
    border-radius: ${border.borderRadius4};
    img {
      width: 100%;
    }

    @media (max-width: ${breakpoints.md}) {
      border-radius: ${border.borderRadius4} !important;
    }

    @media (max-width: ${breakpoints.sm}) {
      height: 138px;
    }
  }

  & > h1 {
    & > img {
      width: 89px;
      height: 89px;
      top: 0;
      left: -90px;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    & > h1 {      
      left: 75px;

      & span {
        margin-left: 15px;
      }

      & > img {
        width: 50px;
        height: auto;
        top: 15px;
        left: -50px;
      }
    }
  }

  @media (max-width: ${breakpoints.sm}) {
    & > h1 {   
      & span {
        display: block;
        margin: 15px 0 0 0;
      }

      & > img {
        width: 38px;
        height: auto;
        top: 15px;
        left: -50px;
      }
    }
    .gatsby-image-wrapper {
      border-radius: ${border.borderRadius4} ${border.borderRadius4} 0 0;
    }
  }
`;

const MenuWrapper = styled('div')`
  width: 100%;
  margin-top: 32px;
`;

const MenuTabs = styled('ul')`
  ${resetList};

  ${flex};
  justify-content: space-between;

  & li {
    width: 100%;
    height: 48px;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }

    & > a {
      width: 100%;
      height: 100%;
      background-color: ${color.minsk};
      transition: 0.3s;
      text-transform: uppercase;
      border-radius: 4px;

      ${flex};

      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;

      &.active,
      &:hover {
        background-color: ${color.bunting};
        border-radius: 4px 4px 0 0;
      }
    }
  }

  @media (max-width: ${breakpoints.sm}) {
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    justify-content: flex-start;

    &::-webkit-scrollbar {
      background-color: transparent;
      display: none;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none !important;
      background: transparent;
      width: 0;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(255, 0, 0, 0.1) !important; // manage scrollbar thumb background color here
    }

    li {
      height: auto;
      margin-right: 3px;
      overflow: initial;
      white-space: nowrap;
      a {
        font-size: ${sizing.text};
        line-height: 16px;
        padding: 15px 20px;
        border-radius: 4px 4px 0 0;
      }
    }
  }
`;

const MenuContent = styled('div')`
  width: 100%;
  height: max-content;

  background-color: ${color.bunting};
  border-radius: 0 0 8px 8px;
  padding: 32px 34px 0 34px;

  ${flex};
  justify-content: space-between;
  flex-wrap: wrap;

  & > a {
    width: 247px;
    height: max-content;
    margin-bottom: 30px;

    & .text {
      width: 100%;
      margin-top: 8px;

      font-family: ${font.default};
      font-style: normal;
      font-weight: normal;
      font-size: ${sizing.text};
      line-height: 15px;
      text-align: center;
      text-transform: uppercase;
      color: ${color.white};
    }
  }
  &.mobile-view {
    display: none;
  }
  @media (max-width: ${breakpoints.s}) {
    & a {
      width: 100%;
    }
    &.desktop-view {
      display: none;
    }
    &.mobile-view {
      display: block;
      padding-top: 70px;
      padding-bottom: 20px;
      
      .slick-slider {
        position: relative;
      }

      .slick-list {
        overflow: hidden;
        & .slick-slide {
          & div {
            margin-bottom: 15px;
          }
        }
      }
      .slick-arrow {
        position: absolute;
        top: -50px;
        right: 0;
        font-size: 0;
        width: 36px;
        height: 36px;
        background-color: #575c74;
        border-radius: 8px;
        border: none;
        transform: rotate(90deg);
        outline: 0;
        
        &:after {
          content: '';
          background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEyIDEzIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICA8Zz4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMTUuNjA3IDE2Ljk3aDguMDN2Mi4xMmgtOC4wM2wzLjQ5IDMuNDktMS41IDEuNS01LjI5LTUuMjljLS40MS0uNDEtLjQxLTEuMDggMC0xLjVsNS4yOS01LjI5IDEuNSAxLjUtMy40OSAzLjQ3eiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIxMyAtNzY1KSB0cmFuc2xhdGUoMTUgNjg5KSB0cmFuc2xhdGUoMTg2IDY0KSIvPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K);
          position: absolute;
          display: block;
          color: #fff;
          font-size: 24px;
          width: 12px;
          height: 12px;
          top: 12px;
          left: 0;
          right: 0;
          margin: auto;
        }
        
        &.slick-prev {
          right: 50px;
          transform: rotate(-90deg);
        }
      }
    }
  }
`;

const ImgWrapper = styled('div')`
  overflow: hidden;
`;

const sliderSettings = {
  dots: false,
  arrows: true,
  infinite: true,
  autoplay: false,
  autoplaySpeed: 5000,
  vertical: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      },
    },
  ],
};

const PlayAnyWhereWrapper = styled('div')`
  border-radius: 8px;
  background-color: ${color.darkPurple};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  margin: 60px 0 18px;
  & h3 {
    font-size: ${sizing.huge};
    color: ${color.lightningYellow};
    font-weight: ${font.headingWeight};
  }
  & .gatsby-image-wrapper {
    max-width: 470px;
    width: 470px;
    margin-right: 85px;
  }
  @media (max-width: ${breakpoints.sm}) {
    & h3 {
      font-size: ${sizing.bigHeading};
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    & h3 {
      font-size: 24px;
      position: absolute;
      z-index: 10;
      left: 10px;
      max-width: 140px;
    }
    & .gatsby-image-wrapper {
      max-width: 240px;
      width: 240px;
      right: -110px;
      margin-right: 0;
    }
    padding-top: 0;
    position: relative;
    overflow: hidden;

    & > div {
      overflow: initial;
    }
  }
  @media (max-width: ${breakpoints.xxs}) {
    & .gatsby-image-wrapper {
      right: -65px;
    }
  }
`;

const PlayItemsWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
`;

const PlayItemWrap = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: ${color.darkPurple};
  width: 23.834%;
  padding: 2.25px 0;
  &:last-child {
    & .gatsby-image-wrapper {
      margin: 0 15px;
    }
  }

  & .gatsby-image-wrapper {
    width: 150px;
  }
  & h4 {
    font-size: ${sizing.heading};
    color: ${color.lightningYellow};
    font-weight: ${font.headingWeight};
    text-transform: uppercase;
    margin: 0;
  }
  @media (max-width: ${breakpoints.md}) {
    h4 {
      font-size: ${sizing.large};
    }
    .gatsby-image-wrapper {
      width: 100px;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    width: 47.227%;
    justify-content: flex-start;
    padding: 14px 0 13px 0;
    margin-bottom: 18px;
    flex-direction: column;
    h4 {
      margin-top: 7px;
      left: 0;
      text-align: center;
      max-width: 85px;
    }
    .gatsby-image-wrapper {
      width: 72px;
      height: 70px;
    }
  }
`;

const GameSlots = ({ data, pageContext }) => {
  // Page data
  const {
    wordpressPage: currentPage,
    allWordpressWpApiMenusMenusItems: { nodes: menuNodes },
  } = data;
  const {
    featured_media,
    yoast_meta,
    title: pageTitle,
    path,
    acf: { 
      game_slots: menuImages,
      title_top: titleTop,
      desc_top: descTop,
      banner_for_play_group: bannerPlayGroup,
      banner_title_for_play_group: bannerTitlePlayGroup,
      play_on_group: playGroup,
    },
  } = currentPage;

  // check if current page is casino - game
  const casinoGamesPage = path === '/casino-games/';

  // Context data
  const { related: relatedIds, breadcrumbs } = pageContext;
  // console.log(data, pageContext);

  // Meta data
  const {
    yoast_wpseo_title: metaTitle = `${pageTitle}- ${process.env.SITE_NAME_SHORT}`,
    yoast_wpseo_metadesc: metaDesc,
  } = yoast_meta;

  // Media data
  const { alt_text } = featured_media;
  const banner = getFile(featured_media);

  // TODO : enable for dev when be completed
  const enable = true;
  return (
    <Layout>
      <SEO title={metaTitle} description={metaDesc} pathname={path} breadcrumbs={breadcrumbs} />
      {enable ? (
        <Wrapper>
          {/* Banner */}
          <FeaturedWrapper>
            <Breadcrumbs breadcrumbs={breadcrumbs} page_title={pageTitle} />
            {banner && <Img fluid={banner} alt={alt_text || pageTitle} />}
            <div className="top-title">
              <img className="iconImage" src={slots} alt="Slots" />
              <span>{pageTitle}</span>
            </div>
          </FeaturedWrapper>
          
          <div className="page_description">
            <h1>{metaTitle}</h1>
            <div className="description_content">{metaDesc}</div>
          </div>
            
          {/* Menu */}
          <MenuWrapper>
            {relatedIds.length && (
              <MenuTabs className="tab-menu">
                {menuNodes[0].items?.map(({ type_label, object_slug, title, path }) => (
                  <li key={type_label + object_slug}>
                    <Link activeClassName="active" to={categoryLinksInterpolate(path)}>
                      {title}
                    </Link>
                  </li>
                ))}
              </MenuTabs>
            )}
            {/* Menu Content for Desktop */}
            {menuImages && (
              <MenuContent className="desktop-view">
                {menuImages?.map(({ acf: { slide_url, slide_title }, altText, localFile }) => (
                  <Link to={validateLink(slide_url)} className="game">
                    <ImgWrapper>
                      <Img fluid={getFile({ localFile })} alt={altText || 'Sbobet'} />
                    </ImgWrapper>
                    <div className="text">
                      <span className="title">{slide_title}</span>
                    </div>
                  </Link>
                ))}
              </MenuContent>
            )}

            {/* Menu Content for Mobile */}
            {menuImages && (
              <MenuContent className="mobile-view slider">
                <Slider {...sliderSettings}>
                  {menuImages?.map(({ acf: { slide_url, slide_title }, altText, localFile, index }) => (
                    <SlickSlideMenuContentMobile
                      key={index}
                      slideUrl={slide_url}
                      img={getFile({ localFile })}
                      title={slide_title}
                      altText={altText || 'Sbobet'}
                    />
                  ))}
                </Slider>
              </MenuContent>
            )}
          </MenuWrapper>

          <SBOSBA mode="sbosba-block"/>
          
          <PlayAnyWhereWrapper className="play-anywhere">
            <h3>{bannerTitlePlayGroup}</h3>
            <ImgWrapper>
              <Img
                fluid={getFile(bannerPlayGroup)}
                alt={bannerPlayGroup?.altText || 'Banner Play where you want'}
                fadeIn={false}
                loading="eager"
              />
            </ImgWrapper>
          </PlayAnyWhereWrapper>
          <PlayItemsWrapper className="play-items">
            {playGroup?.map((item, index) => (
              <PlayItemWrap key={index + item.acf.slide_title}>
                <Img fluid={getFile(item)} alt={item.altText || 'Play icon device'} />
                <h4>{item.acf.slide_title}</h4>
              </PlayItemWrap>
            ))}
          </PlayItemsWrapper>          

          <WhySbobet/>
        </Wrapper>
      ) : (
        <h1 align="center">{dictionary.comingSoon}</h1>
      )}
    </Layout>
  );
};

export default GameSlots;

export const pageQuery = graphql`
    query($id: String!, $related: [Int]) {
        wordpressPage(id: { eq: $id }) {
            wordpress_id
            title
            date(formatString: "MMMM DD, YYYY")
            path
            excerpt
            content
            yoast_meta {
                yoast_wpseo_title
                yoast_wpseo_metadesc
            }
            acf {
                page_template
                title_top
                desc_top
                banner_for_play_group {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 460) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                  alt_text
                }
                banner_title_for_play_group
                play_on_group {
                  acf {
                    slide_url
                    slide_title
                  }
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 150) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                  alt_text
                }
                game_slots {
                    alt_text
                    title
                    id
                    acf {
                        slide_url
                        slide_title
                        slide_description
                    }
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 260) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
            featured_media {
                alt_text
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 1980) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        allWordpressPost(filter: { wordpress_id: { in: $related } }, limit: 4) {
            edges {
                node {
                    slug
                    title
                    excerpt
                    featured_media {
                        alt_text
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 430) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
        allWordpressWpApiMenusMenusItems(filter: { slug: { eq: "games-slots" } }) {
            nodes {
                id
                name
                slug
                items {
                    type_label
                    title
                    object_slug
                    url
                    path
                }
            }
        }
    }
`;
